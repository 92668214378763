<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护管理机构</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="保护管理机构名称"
                    prop="JGMC"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.JGMC"
                        :disabled="!isEdit"
                        placeholder="请填写保护管理机构名称"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="组织机构代码"
                    prop="ZZJGDM"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.ZZJGDM"
                        :disabled="!isEdit"
                        placeholder="请填写组织机构代码,该项为机构信息唯一标识，请确定数据是否准确"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="所在行政区"
                    prop="SZXZQ"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.SZXZQ"
                        :disabled="!isEdit"
                        placeholder="请填写所在行政区"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="承担的责任"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CDDZR"
                        :disabled="!isEdit"
                        placeholder="请填写承担的责任"
                        type="textarea"
                        :autosize="{ minRows: 3 }"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="法定代表人"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.FDDBR"
                        :disabled="!isEdit"
                        placeholder="请填写法定代表人"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="联系人姓名"
                    prop="LXRXM"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.LXRXM"
                        :disabled="!isEdit"
                        placeholder="请填写联系人姓名"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="联系电话"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.LXDH"
                        :disabled="!isEdit"
                        placeholder="请填写联系电话"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="电子邮箱"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.DZYX"
                        :disabled="!isEdit"
                        placeholder="请填写电子邮箱"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="官方网站"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.GFWZ"
                        :disabled="!isEdit"
                        placeholder="请填写官方网站"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="上级管理单位"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.SJGLDW"
                        :disabled="!isEdit"
                        placeholder="请填写上级管理单位"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="机构成立时间"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.JGCLSJ"
                        type="month"
                        :disabled="!isEdit"
                        placeholder="请选择机构成立时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="机构级别"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.JGJB"
                        :disabled="!isEdit"
                        placeholder="请填写机构级别"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="编制人数"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.BZRS"
                        :disabled="!isEdit"
                        placeholder="请填写编制人数"
                        @input="toNumber($event, 'BZRS')"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="工作人员总数"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.GZRYZS"
                        :disabled="!isEdit"
                        placeholder="请填写工作人员总数"
                        @input="toNumber($event, 'GZRYZS')"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="主要经费来源"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.ZYJFLY"
                        :disabled="!isEdit"
                        placeholder="请选择主要经费来源"
                    >
                        <el-option label="财政全额拨款" value="0"></el-option>
                        <el-option label="自收自支" value="1"></el-option>
                        <el-option label="财政差额拨款" value="2"></el-option>
                        <el-option label="其他" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="机构撤销时间" :style="{ width: '80%' }">
                    <el-date-picker
                        v-model="formdata.dataDic.JGCXSJ"
                        type="month"
                        :disabled="!isEdit"
                        placeholder="请选择机构撤销时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="管理区域说明" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.GLQYSM"
                        :disabled="!isEdit"
                        placeholder="请填写管理区域说明"
                        type="textarea"
                        :autosize="{ minRows: 1 }"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";

export default {
    mixins: [infoMixin],
    data() {
        return {
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    JGMC: null,
                    ZZJGDM: null,
                    SZXZQ: null,
                    CDDZR: null,
                    FDDBR: null,
                    LXRXM: null,
                    LXDH: null,
                    DZYX: null,
                    GFWZ: null,
                    SJGLDW: null,
                    JGCLSJ: null,
                    JGJB: null,
                    BZRS: null,
                    GZRYZS: null,
                    ZYJFLY: null,
                    JGCXSJ: null,
                    GLQYSM: null,
                },
                fileids: "",
                itemCode: "20101",
                metaJson: [],
            },
            rules: {
                JGMC: [
                    {
                        required: true,
                        message: "请填写保护管理机构名称",
                        trigger: "blur",
                    },
                ],
                ZZJGDM: [
                    {
                        required: true,
                        message: "请填写组织机构代码",
                        trigger: "blur",
                    },
                ],
                SZXZQ: [
                    {
                        required: true,
                        message: "请填写所在行政区",
                        trigger: "blur",
                    },
                ],
                LXRXM: [
                    {
                        required: true,
                        message: "请填写联系人姓名",
                        trigger: ["blur", "change"],
                    },
                ],
                LXDH: [
                    {
                        required: false,
                        message: "此项必须填数字",
                        trigger: ["blur", "change"],
                    },
                ],
            },
        };
    },
    methods: {
        GetDataNext() {
            // 服务返回为数字类型  选择器需字符串 故需转换
            console.log(
                this.formdata.dataDic.ZYJFLY,
                this.enumObj.ZYJFLY,
                "服务返回为数字类型  选择器需字符串 故需转换"
            );
            if (this.formdata.dataDic.ZYJFLY) {
                this.formdata.dataDic.ZYJFLY = String(
                    this.formdata.dataDic.ZYJFLY
                );
            }
        },
    },
};
</script>

<style></style>
